import React from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase.config'; // Firebase config
import { FiArrowLeft } from 'react-icons/fi'; // Example of Feather icon for arrow
import { FaTicketAlt, FaMoneyCheckAlt, FaUserAlt, FaSignOutAlt } from 'react-icons/fa'; // FontAwesome icons
import '../CSS/Sidebar.css';

export default function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        // After signing out, navigate to the home page
        navigate('/', { replace: true });
      })
      .catch((error) => {
        console.error('Error during logout:', error);
      });
  };

  return (
    <div className="bg-dark text-white p-3" style={{ width: '250px', minHeight: '100vh' }}>
      <Nav className="flex-column mt-4">
        <Nav.Link onClick={() => navigate('/')} className="text-white d-flex sb" style={{ borderBottom: '1px solid white' }}>
          <FiArrowLeft className="me-2" /> View Website
        </Nav.Link>
        <Nav.Link onClick={() => navigate('/mycards')} className="text-white d-flex sb" style={{ borderBottom: '1px solid white' }}>
          <FaTicketAlt className="me-2" /> My Coupons
        </Nav.Link>
        {/* <Nav.Link onClick={() => navigate('/pay')} className="text-white d-flex sb" style={{ borderBottom: '1px solid white' }}>
          <FaMoneyCheckAlt className="me-2" /> Payments
        </Nav.Link> */}
        <Nav.Link onClick={() => navigate('/updateprofile')} className="text-white d-flex sb" style={{ borderBottom: '1px solid white' }}>
          <FaUserAlt className="me-2" /> Profile Details
        </Nav.Link>
        {/* <Nav.Link onClick={handleLogout} className="text-white d-flex sb" style={{ borderBottom: '1px solid white' }}>
          <FaSignOutAlt className="me-2" /> Logout
        </Nav.Link> */}
      </Nav>
    </div>
  );
}

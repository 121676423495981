import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row, Form } from 'react-bootstrap';
import axios from 'axios';
import { auth } from '../firebase.config';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import UserHeader from './UserHeader';

export default function UpdateProfile() {
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        dob: '',
        address: '',
        memberId: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false); // New state for toggling sidebar

    // Check if the session is active
    const isSessionActive = () => {
        return localStorage.getItem("session") !== null;
    };

    //formate dob
    const formatDOB = (dateString) => {
        if (!dateString) return '';
        const dateObject = new Date(dateString);
        return dateObject.toISOString().split('T')[0];
      };

    // Check authentication status and redirect if not authenticated
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
                if (!isSessionActive()) {
                    navigate('/signupform'); // Redirect to login if session is not set
                }
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    // Fetch logged-in user's profile data using their phone number
    useEffect(() => {
        const fetchProfileData = async () => {
            const phoneNumber = localStorage.getItem('userPhoneNumber'); 
            if (!phoneNumber){
                return;
            }  
            try {
                
                //const response = await axios.post(`https://discountfeast.co.in/newmember/get-member/${phoneNumber}`);
                const response = await axios.post(`https://discountfeast.co.in/newmember/getmember`,{phoneNumber:phoneNumber});
                const respData = response?.data;

                if(respData?.success){
                    const formattedDob = formatDOB(respData?.member?.dob);
                    setProfileData({...respData?.member, dob: formattedDob});
                }
            } catch (error) {
               alert("Uable to get user details");
            }
        };
        fetchProfileData();
    }, []);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };

    // Handle form submission for updating the profile
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.put(`https://discountfeast.co.in/newmember/updatemember`, {
                memberId: profileData.memberId,
                name: profileData.name,
                email: profileData.email,
                dob: profileData.dob,
                address: profileData.address,
            });
            const respData = response.data;

            if(respData?.success){
                alert('Profile updated successfully');
            }else{
                alert('Profile not update');
            }

        } catch (err) {
            setError('Failed to update profile. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <UserHeader />
            {/* Sidebar toggle button for mobile view */}
      <Button
        className="d-block d-md-none mb-0 bg-dark"
        onClick={() => setShowSidebar(!showSidebar)}
        style={{ marginLeft: '0px', color: 'white',border:'none', borderRadius:'0px' ,width:'100%',backgroundColor:'#495057'}}
      >
        {showSidebar ? 'Hide Menu' : 'Show Menu'}
      </Button>

      <div className="d-flex" style={{ borderBottom: '1px solid white' }}>
        {/* Conditional rendering of sidebar */}
        {(showSidebar || window.innerWidth >= 768) && <Sidebar />}
         
               
                {/* Main content */}
                <div className="flex-grow-1 p-0">
                    <Container className="d-grid align-items-center p-0 w-100" style={{ maxWidth: '100%' }}>
                        <Row className="w-100">
                            <Col lg={12} className="form align-items-center justify-content-center bgimage p-0">
                                <div className="content-wrapper justify-content-center w-100">
                                    <div className="form-container mx-auto justify-content-center" style={{ padding: '0 10%' }}>
                                        <h2 className="signuph2 pt-5 text-center">Update Profile</h2>

                                        {error && <p className="text-danger text-center">{error}</p>}
                                        {loading ? (
                                            <p>Loading...</p>
                                        ) : (
                                            profileData && (
                                                <Form onSubmit={handleSubmit}>
                                                    <Row className="justify-content-center">
                                                        <Col lg={4} md={6} sm={6}>
                                                            <Form.Group controlId="name" className="mb-3">
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Name"
                                                                    name="name"
                                                                    value={profileData?.name || ''}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6} sm={6}>
                                                            <Form.Group controlId="memberId" className="mb-3">
                                                                <Form.Label>Member ID</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Member ID"
                                                                    name="memberId"
                                                                    value={profileData?.memberId || ''}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={4} md={6} sm={6}>
                                                            <Form.Group controlId="email" className="mb-3">
                                                                <Form.Label>Email</Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    placeholder="Email"
                                                                    name="email"
                                                                    value={profileData?.email || ''}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6} sm={6}>
                                                            <Form.Group controlId="dob" className="mb-3">
                                                                <Form.Label>Date of Birth</Form.Label>
                                                                <Form.Control
                                                                    type="date"
                                                                    placeholder="MM / DD / YYYY"
                                                                    name="dob"
                                                                    value={profileData?.dob || ''}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={4} md={6} sm={6}>
                                                            <Form.Group controlId="address" className="mb-3">
                                                                <Form.Label>Address</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Address"
                                                                    name="address"
                                                                    value={profileData?.address || ''}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={4} sm={12} className="d-flex justify-content-center mb-2">
                                                            <Button
                                                                variant="warning"
                                                                style={{ width: '100%', backgroundColor: '#EF4C0B', color: 'white', border: 'none' }}
                                                                type="submit"
                                                            >
                                                                Update Profile
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
           
            </div>
            <Footer />
        </>
    );
}

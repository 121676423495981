
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../CSS/Pay.css'; // Assuming this contains the styles
import { IoClose } from "react-icons/io5";
import { auth } from '../firebase.config'; // Firebase config
import Sidebar from './Sidebar';
import UserHeader from './UserHeader';

export default function Pay() {
  const [bankDetails, setBankDetails] = useState([]);
  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState('');
  const [showPaybox, setShowPaybox] = useState(true); // To control visibility of the paybox
  const navigate = useNavigate(); // Initialize the navigation hook
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check if the session is active
  const isSessionActive = () => {
    return localStorage.getItem("session") !== null;
  };

  // Check authentication status and redirect if not authenticated
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        if (!isSessionActive()) {
          navigate('');  // Redirect to login if session is not set
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // useEffect(() => {
  //   const fetchAmount = async () => {
  //     try {
  //       const response = await axios.get('https://discountfeast.co.in/newpay/amount');
  //       setAmount(response.data.amount);
  //     } catch (error) {
  //       console.error('Error fetching amount', error);
  //     }
  //   };

  //   fetchAmount();
  // }, []);

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const response = await axios.get('https://discountfeast.co.in/bankdetails/getbankdetails');
        setBankDetails(response.data);
      } catch (error) {
        console.error('Error fetching bank details', error);
      }
    };

    fetchBankDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const paymentDetails = {
    //   amount,
    // };

    try {
      // const response = await axios.post('https://discountfeast.co.in/newpay/confirm', paymentDetails);
      setMessage('Your procedure is completed!');
    } catch (error) {
      setMessage('Error processing payment. Please try again.');
    }
  };

  const handleClose = () => {
    setShowPaybox(false); // Hides the paybox when close is clicked
    navigate('/'); // Redirect to the sign-in page
  };

  if (!showPaybox) {
    return null; // Don't render anything if the paybox is closed
  }

  return (
    <>
      <div className="d-flex regpay" style={{ borderBottom: '1px solid white', backgroundColor: 'transparent' }}>
        {/* Main content */}
        <div className="flex-grow-1 p-0">
          <Container className='m-0 p-0'>
            <div className="paybox align-item-center ">
              <Row className="justify-content-center">
                <Col lg={12} className="paycontent">
                <form onSubmit={handleSubmit}>
                    <Row className="mt-0">
                      {bankDetails.map((detail, index) => (
                        <Col key={index} className="d-flex bank-details mb-3">
                  <h3>Let’s Make Payment</h3>
                  <p style={{ color: '#575757' }}>
                    To complete your subscription, please use the bank details below for payment.
                  </p>
                  <h5 style={{ color: '#575757' }}>You’re paying,</h5> 
                  <br />
                  {/* <h1>₹{amount.toFixed(2)}</h1> */}
                 
                  
                  <h1 style={{ marginTop: '10px',  marginRight:'100%' }}>₹{detail.subscriptionamount || 'Fetching...'}.00</h1>
                 

                  
                          <Col lg={8} md={12} sm={12} className="d-flex flex-column">
                            <Row className="d-flex flex-row mb-2 align-items-center">
                              <Col lg={12} className="font-weight-bold"><strong>Bank Name:</strong>  {detail.bankName || 'Fetching...'}</Col>
                              {/* <Col lg={7}></Col> */}
                            </Row>
                            <Row className="d-flex flex-row mb-2 align-items-center">
                              <Col lg={12} className="font-weight-bold"><strong>Payee Name:</strong> {detail.payeeName || 'Fetching...'}</Col>
                              {/* <Col lg={7}>{detail.payeeName || 'Fetching...'}</Col> */}
                            </Row>
                            <Row className="d-flex flex-row mb-2 align-items-center">
                              <Col lg={12} className="font-weight-bold"><strong>Account Number:</strong> {detail.accountNumber || 'Fetching...'}</Col>
                              {/* <Col lg={7}>{detail.accountNumber || 'Fetching...'}</Col> */}
                            </Row>
                            <Row className="d-flex flex-row mb-2 align-items-center">
                              <Col lg={12} className="font-weight-bold"><strong>IFSC Code:</strong>{detail.ifscCode || 'Fetching...'}</Col>
                              {/* <Col lg={7}>{detail.ifscCode || 'Fetching...'}</Col> */}
                            </Row>
                            <Row className="d-flex flex-row mb-2 align-items-center">
                              <Col lg={12} className="font-weight-bold"><strong>Branch Name:</strong> {detail.branchName || 'Fetching...'}</Col>
                              {/* <Col lg={7}>{detail.branchName || 'Fetching...'}</Col> */}
                            </Row>
                            <Row className="d-flex flex-row mb-2 align-items-center">
                              <Col lg={12} className="font-weight-bold"><strong>Reference Number:</strong> {detail.branchReferenceNumber || 'Fetching...'}</Col>
                              {/* <Col lg={7}>{detail.branchReferenceNumber || 'Fetching...'}</Col> */}
                            </Row>
                          </Col>
                          <Col lg={4} md={12} sm={12} className="qr-code">
                            <img
                              src={`https://discountfeast.co.in/${detail.image}`}
                              alt={detail.image}
                              className="custom-coupon-image"
                              style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                            />
                          </Col>
                        </Col>
                      ))}
                    </Row>

                  </form>

                  {message && <p className="mt-3">{message}</p>}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

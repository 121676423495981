import React, { useState, useRef, useEffect } from 'react';
import { auth } from '../firebase.config';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import UserHeader from './UserHeader';
import Footer from './Footer';
import { FaArrowLeft } from 'react-icons/fa'; // Importing back arrow icon

export default function TermCondition() {
    const navigate = useNavigate();
  
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            // User is signed in, navigate to the dashboard
            navigate('/aboutus'); // Adjust the route as needed
          } else {
            // No user is signed in, navigate to the login page
            navigate('/aboutus');
          }
        });
    
        // Cleanup subscription on unmount
        return () => unsubscribe();
      }, [navigate]);

    return (
        <>
            <UserHeader />

            <Container className="mt-4 mb-5">
                 {/* Back Arrow Button */}
                 <button 
                            className="btn btn-link mb-3 justify-content-center" 
                            onClick={() => navigate('/')} // Go back to the previous page
                            style={{ fontSize: '20px', color: 'black', textDecoration: 'none', display:'flex'}}
                        >
                            <FaArrowLeft/> 
                        </button>
                <Row className="justify-content-center">
                    <Col md={10}>
                       
                        <Card className="p-4" style={{ border: 'none' }}>
                            <Card.Body>
                                <h3 className="text-center mb-5">Welcome to Discount Feast – Your Ultimate Dining Savings Destination!</h3>

                                <section className="terms-section">
                                    <p>
                                    At Discount Feast, we believe that enjoying great food should be accessible to everyone. Our platform is dedicated to helping you discover and enjoy the best dining experiences at unbeatable prices. Here’s a closer look at the services we offer:
                                    </p>
                                </section>

                                <section className="terms-section">
                                    <h5>1. Exclusive Restaurant Discounts:</h5>
                                    <p>We partner with a wide range of restaurants to provide you with exclusive discounts and offers. Whether you’re craving local cuisine, international dishes, or trendy cafes, you can save significantly while enjoying your meal.</p>
                                </section>
                                <section className="terms-section">
                                    <h5>2. Curated Dining Deals:</h5>
                                    <p>Explore our curated selection of dining deals that are updated regularly. We make it easy for you to find the best offers in your area, ensuring that you never miss out on a great dining experience.</p>
                                </section>
                                <section className="terms-section">
                                    <h5>3. User-Friendly Platform:</h5>
                                    <p>Our website is designed with you in mind. Easily navigate through various categories, filter by cuisine or location, and find the perfect restaurant that fits your taste and budget.</p>
                                </section>
                                <section className="terms-section">
                                    <h5>4. Special Events and Promotions:</h5>
                                    <p>Stay tuned for special events and promotions, including holiday deals and seasonal discounts. We aim to enhance your dining experience with unique opportunities to save even more.</p>
                                </section>
                                <section className="terms-section">
                                    <h5>5. Customer Support:</h5>
                                    <p>Our dedicated support team is here to assist you with any queries or issues. Whether you need help with a booking or have questions about a deal, we’re just a click away.</p>
                                </section>
                                <section className="terms-section">
                                    <h5>6. Community Feedback:</h5>
                                    <p>We value your experience! Share your reviews and feedback on our platform, helping us and other diners make informed decisions about where to eat.</p>
                                </section>
                                <section className="terms-section">
                                    <p>Join the Discount Feast community today and start savoring delicious meals without breaking the bank. Discover how easy and rewarding dining can be with our exceptional services!</p>
                                </section>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    );
}

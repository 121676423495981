import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { auth } from '../firebase.config';
import logoimage from '../assets/DF-LOGO1 (2).png';
import { useNavigate } from 'react-router-dom';

export default function UserHeader() {
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;
  // Check if the session is active
  const isSessionActive = () => {
    return localStorage.getItem("session") !== null;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserName(user.displayName || 'My');
      } else {
        setUserName('');
        // Redirect to signup page if session is not active
        if (!isSessionActive()) {
          navigate('');
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = () => {
    auth.signOut().then(() => {
      // Clear session on logout
      localStorage.removeItem("session");
      navigate('/'); // Redirect to home page after logout
    });
  };

  return (
    <>
      <Navbar expand="md" className="py-0 bg-dark px-3" style={{ borderBottom: '1px solid white' }}>
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logoimage} alt="Logo" style={{ maxHeight: '80px' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-light" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            {userName ? (
              <>
                <Button
                  variant="outline-light"
                  onClick={() => navigate('/myprofile')}
                  className="me-2"
                  style={{  fontSize: '14px',
                    padding: '8px 15px',
                    borderRadius: '20px',
                    // backgroundColor: '#EF4C0B',
                    width: '140px',
                    // border: 'none',
                    marginBottom: isMobile ? '10px' : '0',
                    // padding: '8px 15px', borderRadius: '20px' 
                  }}
                >
                  {userName} Account
                </Button>
                <Button
                  variant="outline-light"
                  onClick={handleLogout}
                  style={{  fontSize: '14px',
                    padding: '8px 15px',
                    borderRadius: '20px',
                    // backgroundColor: '#EF4C0B',
                    width: '80px',
                    // border: 'none',
                    marginBottom: isMobile ? '10px' : '0',
                    // padding: '8px 15px', borderRadius: '20px' 
                     }}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
              <Button
              variant="secondary"
              className="mx-2"
              style={{
                color:'black',
                fontSize: '14px',
                padding: '8px 15px',
                borderRadius: '20px',
                backgroundColor: '#FBBC05',
                width: '180px',
                border: 'none',
                marginBottom: isMobile ? '10px' : '0', // Margin-bottom for mobile
              }}
              onClick={() => navigate('/requestmembership')}
            >
              Purchase Membership
            </Button>
            <Button
              variant="secondary"
              className="mx-2"
              style={{
                color:'black',
                fontSize: '14px',
                padding: '8px 15px',
                borderRadius: '20px',
                backgroundColor: '#FBBC05',
                width: '180px',
                border: 'none',
                marginBottom: isMobile ? '10px' : '0', // Margin-bottom for mobile
              }}
              onClick={() => navigate('/signin')}
            >
              One Time Registration
            </Button>
            <Button
              variant="secondary"
              className="mx-2"
              style={{
                color:'black',
                fontSize: '14px',
                padding: '8px 15px',
                borderRadius: '20px',
                backgroundColor: '#FBBC05',
                width: '180px',
                marginBottom: isMobile ? '10px' : '0', // Margin-bottom for mobile
              }}
              onClick={() => navigate('/signupform')}
            >
             Member Login
            </Button>
            <Button
  variant="secondary"
  className="mx-2"
  style={{
    color: 'black',
    fontSize: '14px',
    padding: '8px 15px',
    borderRadius: '20px',
    backgroundColor: '#FBBC05',
    width: '180px',
    marginBottom: isMobile ? '10px' : '0', // Margin-bottom for mobile
  }}
  onClick={() => {
    // Open the Google Forms link in a new tab
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSeI4OSDots8wwE6ypM3r_DFcNfhkDWXnSmD8OZJC8VwuV3_Yw/viewform?vc=0&c=0&w=1&flr=0', '_blank');
    // Navigate to the signup form
    // navigate('/signupform');
  }}
>
  Join Sales Team
</Button>

              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCF1rC4MNZQIIcgs5jdAEstJn3bdR8af5g",
  authDomain: "discountfeast-f430f.firebaseapp.com",
  projectId: "discountfeast-f430f",
  storageBucket: "discountfeast-f430f.appspot.com",
  messagingSenderId: "1059962887123",
  appId: "1:1059962887123:web:30348430620c3ce752ed26",
  measurementId: "G-LRZYNBXSX1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Export the authentication and Recaptcha functions
export { auth, RecaptchaVerifier, signInWithPhoneNumber };

export default app;

// import React, { useState, useRef, useEffect } from 'react';
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import { auth } from '../firebase.config';
// import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
// import axios from 'axios';
// import '../CSS/SignUp.css';
// import { useNavigate } from 'react-router-dom';
// import logoimage from '../assets/Background Removed - Discount Feast Logo.png';
// // import { useNavigate } from 'react-router-dom';
// import Footer from './Footer';
// import Header from './Header';
// import UserHeader from './UserHeader';

// export default function SignUpform() {
//   const [memberDetails, setMemberDetails] = useState({});
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [otp, setOtp] = useState('');
//   const [verificationId, setVerificationId] = useState(null);
//   const [otpSent, setOtpSent] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [loading, setLoading] = useState(false);
//   const recaptchaVerifierRef = useRef(null);
//   const navigate = useNavigate();
//   const otpInputRef = useRef(null);

//   // Handle sending OTP after checking the phone number in the database
//   const sendOtp = async () => {
//     setLoading(true);
//     setSuccess('');
//     setError('');

//     try {
//       const response = await axios.post('https://discountfeast.co.in/newmember/checkPhoneNumber', { phoneNumber });

//       if (response.status === 200 && response.data.exists) {
//         if (!recaptchaVerifierRef.current) {
//           recaptchaVerifierRef.current = new RecaptchaVerifier(
//             'recaptcha-container',
//             {
//               size: 'invisible',
//               callback: (response) => console.log('reCAPTCHA solved', response),
//               'expired-callback': () => console.log('reCAPTCHA expired'),
//             },
//             auth
//           );
//         }

//         const appVerifier = recaptchaVerifierRef.current;
//         const fullPhoneNumber = `+91${phoneNumber}`;
//         const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
//         setVerificationId(confirmationResult.verificationId);
//         setOtpSent(true);
//         setError('');
//         setLoading(false);

//         if (otpInputRef.current) {
//           otpInputRef.current.focus();
//         }
//       } else {
//         setError('Phone number not found. Please register.');
//         setLoading(false);
//       }
//     } catch (err) {
//       console.error('Error sending OTP:', err);
//       setError('Failed to send OTP. Please try again.');
//       setLoading(false);
//     }
//   };

//   // Verify OTP
//   const verifyOtp = async () => {
//     if (!otp || !verificationId) {
//       setError('Please enter the OTP.');
//       return;
//     }

//     setLoading(true);

//     try {
//       const credential = PhoneAuthProvider.credential(verificationId, otp);
//       await signInWithCredential(auth, credential);
//       setSuccess('OTP Verified Successfully!');
//       setError('');

//       await verifyPhoneNumberWithBackend();
//     } catch (err) {
//       console.error('Error verifying OTP:', err);
//       setError('Invalid OTP. Please enter the correct OTP.');
//       setLoading(false);
//     }
//   };

//   const verifyPhoneNumberWithBackend = async () => {
//     try {
//       // Verify phone number with backend
//       const verifyResponse = await axios.post('https://discountfeast.co.in/newmember/verifylogin', { phoneNumber });
      
//       if (verifyResponse.status === 200) {
//         setSuccess('Phone number verified successfully!');
//         localStorage.setItem('userPhoneNumber', phoneNumber); // Store phone number in local storage
  
//         // Fetch user data from backend
//         const userResponse = await axios.get(`https://discountfeast.co.in/newmember/all/${phoneNumber}`);
  
//         if (userResponse.status === 200) {
//           const userData = userResponse.data;
  
//           // Optionally store user data in local storage or in the state
//           localStorage.setItem('userData', JSON.stringify(userData)); // Save user data to localStorage
//           console.log('User Data:', userData); // You can handle user data further in your app
  
//           // Navigate to the home or user profile page
//           navigate('/');
//         } else {
//           setError('Failed to fetch user data.');
//         }
//       } else {
//         setError('Phone number not verified or not found.');
//       }
//     } catch (err) {
//       setError('Error verifying phone number: ' + (err.response?.data?.message || err.message));
//     }
//   };
  

//   useEffect(() => {
//     if (phoneNumber.length === 10 && !otpSent) {
//       sendOtp();
//     }
//   }, [phoneNumber]);

//   useEffect(() => {
//     if (otp.length === 6 && verificationId) {
//       verifyOtp();
//     }
//   }, [otp]);

//     // const navigate = useNavigate();
  
//     useEffect(() => {
//       const unsubscribe = auth.onAuthStateChanged((user) => {
//         if (user) {
//           // User is signed in, navigate to the dashboard
//           navigate('/'); // Adjust the route as needed
//         } else {
//           // No user is signed in, navigate to the login page
//           navigate('/signupform');
//         }
//       });
  
//       // Cleanup subscription on unmount
//       return () => unsubscribe();
//     }, [navigate]);

//   return (
//     <>
//       <Col lg={12}>
//         <UserHeader/>
//       </Col>
//       <Container fluid className="align-items-center justify-content-center p-0 w-100">
//         <Row className="w-100" style={{ marginLeft: '0px' }}>
//           <Col lg={12} md={12} sm={12} xs={12} className="form1 align-items-center bgimage1 pt-5">
//             <div className="content-wrapper w-100">
//               <div className="signinfrm form-container mx-auto" style={{ padding: '0 10%' }}>
//                 <h2 className="signuph2 text-center">Login to Discount Feast</h2>

//                 <Form className="d-grid align-items-center">
//                   <Row className="justify-content-center">
//                     <Col lg={4} sm={12} md={12} xs={12}>
//                       <Form.Group controlId="PhoneNumber" className="mb-3">
//                         <Form.Label className="forminput">Phone Number</Form.Label>
//                         <div className="d-flex">
//                           <Form.Control
//                             className="inputfield"
//                             type="text"
//                             placeholder="Enter Phone Number"
//                             value={phoneNumber}
//                             onChange={(e) => {
//                               setPhoneNumber(e.target.value);
//                               setMemberDetails({ ...memberDetails, phoneNumber: e.target.value });
//                             }}
//                             required
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                   </Row>

//                   <Row className="justify-content-center">
//                     <Col lg={4} sm={12} md={12} xs={12}>
//                       <Form.Group controlId="otp" className="mb-3">
//                         <Form.Label className="forminput">OTP</Form.Label>
//                         <Form.Control
//                           className="inputfield"
//                           type="text"
//                           placeholder="6 characters"
//                           value={otp}
//                           onChange={(e) => setOtp(e.target.value)}
//                           required
//                           ref={otpInputRef}
//                         />
//                       </Form.Group>
//                     </Col>
//                   </Row>

//                   {error && (
//                     <div className="text-center mt-2">
//                       <p className="text-danger">{error}</p>
//                     </div>
//                   )}
//                   {success && (
//                     <div className="text-center mt-2">
//                       <p className="text-success">{success}</p>
//                     </div>
//                   )}

//                   <Row style={{ padding: '0 30px' }}>
//                     <Col lg={12} md={10} sm={12} xs={12} className="d-flex justify-content-center mb-2">
//                       <Button
//                         variant="warning"
//                         style={{ width: '25%', backgroundColor: '#EF4C0B', color: 'white', border: 'none' }}
//                         onClick={verifyOtp}
//                         disabled={loading}
//                       >
//                         {loading ? 'Processing...' : 'Login'}
//                       </Button>
//                     </Col>
//                   </Row>

//                   <Row>
//                     <Col lg={12} md={12} sm={12} xs={12} className="text-center mt-2">
//                       Not a member yet? <a href="/signin">Purchase Membership</a>
//                     </Col>
//                   </Row>

//                   <Col lg={12} className="mt-2 text-center">
//                     <small>
//                       This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
//                     </small>
//                     <div id="recaptcha-container"></div>
//                   </Col>
//                 </Form>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <Footer />
//     </>
//   );
// }


import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { auth } from '../firebase.config';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import axios from 'axios';
import '../CSS/SignUp.css';
import { useNavigate } from 'react-router-dom';
import logoimage from '../assets/Background Removed - Discount Feast Logo.png';
// import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import UserHeader from './UserHeader';

export default function SignUpform() {
  const [memberDetails, setMemberDetails] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const recaptchaVerifierRef = useRef(null);
  const navigate = useNavigate();
  const otpInputRef = useRef(null);

  // Handle sending OTP after checking the phone number in the database
  const sendOtp = async () => {
    setLoading(true);
    setSuccess('');
    setError('');

    try {
      const response = await axios.post('https://discountfeast.co.in/newmember/checkPhoneNumber', { phoneNumber });

      if (response.status === 200 && response.data.exists) {
        if (!recaptchaVerifierRef.current) {
          recaptchaVerifierRef.current = new RecaptchaVerifier(
            'recaptcha-container',
            {
              size: 'invisible',
              callback: (response) => console.log('reCAPTCHA solved', response),
              'expired-callback': () => console.log('reCAPTCHA expired'),
            },
            auth
          );
        }

        const appVerifier = recaptchaVerifierRef.current;
        const fullPhoneNumber = `+91${phoneNumber}`;
        const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
        setVerificationId(confirmationResult.verificationId);
        setOtpSent(true);
        setError('');
        setLoading(false);

        if (otpInputRef.current) {
          otpInputRef.current.focus();
        }
      } else {
        setError('Phone number not found. Please register.');
        setLoading(false);
      }
    } catch (err) {
      console.error('Error sending OTP:', err);
      setError('Failed to send OTP. Please try again.');
      setLoading(false);
    }
  };

  // Verify OTP
  const verifyOtp = async () => {
    if (!otp || !verificationId) {
      setError('Please enter the OTP.');
      return;
    }

    setLoading(true);

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      setSuccess('OTP Verified Successfully!');
      setError('');

      await verifyPhoneNumberWithBackend();
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError('Invalid OTP. Please enter the correct OTP.');
      setLoading(false);
    }
  };

  const verifyPhoneNumberWithBackend = async () => {
    try {
      // Verify phone number with backend
      const verifyResponse = await axios.post('https://discountfeast.co.in/newmember/verifylogin', { phoneNumber });
      
      if (verifyResponse.status === 200) {
        setSuccess('Phone number verified successfully!');
        localStorage.setItem('userPhoneNumber', phoneNumber); // Store phone number in local storage
  
        // Fetch user data from backend
        const userResponse = await axios.get(`https://discountfeast.co.in/newmember/all/${phoneNumber}`);
  
        if (userResponse.status === 200) {
          const userData = userResponse.data;
  
          // Optionally store user data in local storage or in the state
          localStorage.setItem('userData', JSON.stringify(userData)); // Save user data to localStorage
          console.log('User Data:', userData); // You can handle user data further in your app
  
          // Navigate to the home or user profile page
          navigate('/');
        } else {
          setError('Failed to fetch user data.');
        }
      } else {
        setError('Phone number not verified or not found.');
      }
    } catch (err) {
      setError('Error verifying phone number: ' + (err.response?.data?.message || err.message));
    }
  };
  

  useEffect(() => {
    if (phoneNumber.length === 10 && !otpSent) {
      sendOtp();
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (otp.length === 6 && verificationId) {
      verifyOtp();
    }
  }, [otp]);

    // const navigate = useNavigate();
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, navigate to the dashboard
          navigate('/'); // Adjust the route as needed
        } else {
          // No user is signed in, navigate to the login page
          navigate('/signupform');
        }
      });
  
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, [navigate]);

  return (
    <>
      <Col lg={12}>
      <UserHeader/>
      </Col>
      <Container fluid className="align-items-center justify-content-center p-0 w-100">
        <Row className="w-100" style={{ marginLeft: '0px' }}>
          <Col lg={12} md={12} sm={12} xs={12} className="form1 align-items-center bgimage1 pt-5">
            <div className="content-wrapper w-100">
              <div className="signinfrm form-container mx-auto" style={{ padding: '0 10%' }}>
                <h2 className="signuph2 text-center">Login to Discount Feast</h2>

                <Form className="d-grid align-items-center">
                  <Row className="justify-content-center">
                    <Col lg={4} sm={12} md={12} xs={12}>
                      <Form.Group controlId="PhoneNumber" className="mb-3">
                        <Form.Label className="forminput">Phone Number</Form.Label>
                        <div className="d-flex">
                          <Form.Control
                            className="inputfield"
                            type="text"
                            placeholder="Enter Phone Number"
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                              setMemberDetails({ ...memberDetails, phoneNumber: e.target.value });
                            }}
                            required
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col lg={4} sm={12} md={12} xs={12}>
                      <Form.Group controlId="otp" className="mb-3">
                        <Form.Label className="forminput">OTP</Form.Label>
                        <Form.Control
                          className="inputfield"
                          type="text"
                          placeholder="6 characters"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          required
                          ref={otpInputRef}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {error && (
                    <div className="text-center mt-2">
                      <p className="text-danger">{error}</p>
                    </div>
                  )}
                  {success && (
                    <div className="text-center mt-2">
                      <p className="text-success">{success}</p>
                    </div>
                  )}

                  <Row  className="justify-content-center" style={{ padding: '0 30px' }}>
                    <Col lg={4} md={10} sm={12} xs={12} className="d-flex justify-content-center mb-2">
                      <Button
                        variant="warning"
                        style={{ width: '100%', backgroundColor: '#EF4C0B', color: 'white', border: 'none' }}
                        onClick={verifyOtp}
                        disabled={loading}
                      >
                        {loading ? 'Processing...' : 'Login'}
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    
                    <Col lg={12} md={12} sm={12} xs={12} className="text-center mt-2">
                    Not a member yet?<Button
              variant="secondary"
              className="mx-2"
              style={{
                fontSize: '14px',
                padding: '0px 0px',
                borderRadius: 'none',
                color:'#0D6EFD',
                backgroundColor:'transparent',
                border:'none'
                
              }}
              onClick={() => navigate('/requestmembership')}
            >
              Purchase Membership
            </Button>
                      
                    </Col>
                  </Row>

                  <Col lg={12} className="mt-2 text-center">
                    <small>
                      This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
                    </small>
                    <div id="recaptcha-container"></div>
                  </Col>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

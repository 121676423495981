// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Col, Container, Row, Button, Modal, Table } from 'react-bootstrap';
// import Footer from './Footer';
// import { auth } from '../firebase.config'; // Firebase config
// import Sidebar from './Sidebar';
// import UserHeader from './UserHeader';
// import { useNavigate } from 'react-router-dom';

// export default function Offercards() {
//   const [coupons, setCoupons] = useState([]);
//   const [selectedCoupon, setSelectedCoupon] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();
//   const [showSidebar, setShowSidebar] = useState(false); // New state for toggling sidebar

//   const checkStatus = (redeemedAt) => {
//     const redeemedDate = new Date(redeemedAt);
//     const currentDate = new Date();
//     const timeDifference = currentDate - redeemedDate;
//     const hoursDifference = timeDifference / (1000 * 60 * 60);
//     return hoursDifference <= 24;
//   };

//   const isSessionActive = () => {
//     return localStorage.getItem("session") !== null;
//   };

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//         if (user) {
//             setIsLoggedIn(true);
//         } else {
//             setIsLoggedIn(false);
//             if (!isSessionActive()) {
//                 navigate('/signupform'); // Redirect to login if session is not set
//             }
//         }
//     });

//     return () => unsubscribe();
// }, [navigate]);

//   useEffect(() => {
//     const phoneNumber = localStorage.getItem('userPhoneNumber');
//     const fetchCoupons = async () => {
//       try {
//         const response = await axios.post(
//           'https://discountfeast.co.in/couponredeem/getRedeemedDtlByMobNo',
//           { phoneNumber: phoneNumber }
//         );

//         const respData = response?.data;
//         if (respData?.success) {
//           setCoupons(respData?.couponDetails);
//         } else {
//           setCoupons([]);
//         }
//       } catch (error) {
//         setCoupons([]);
//       }
//     };

//     fetchCoupons();
//   }, []);

//   const handleView = (coupon) => {
//     setSelectedCoupon(coupon);
//     setShowModal(true);
//   };

//   return (
//     <>
//       <UserHeader />
//       {/* Sidebar toggle button for mobile view */}
//       <Button
//         className="d-block d-md-none mb-0 bg-dark"
//         onClick={() => setShowSidebar(!showSidebar)}
//         style={{ marginLeft: '0px', color: 'white',border:'none', borderRadius:'0px' ,width:'100%',backgroundColor:'#495057'}}
//       >
//         {showSidebar ? 'Hide Menu' : 'Show Menu'}
//       </Button>

//       <div className="d-flex" style={{ borderBottom: '1px solid white' }}>
//         {/* Conditional rendering of sidebar */}
//         {(showSidebar || window.innerWidth >= 768) && <Sidebar />}
        
//         <div className="flex-grow-1 p-3" style={{ overflow: 'auto', maxWidth: '100%' }}>
//           <Row>
//             <Col xs={12}>
//             <h2 className="signuph2 pt-5 text-center">
//                 My Coupons
//               </h2>
//             </Col>
//           </Row>
//           <Container>
//             <div className="table-responsive" style={{ overflow: 'auto', maxWidth: '100%' }}>
//               <Table striped bordered hover className="table-sm" style={{ minWidth: '800px' }}>
//                 <thead className="thead-dark">
//                   <tr>
//                     <th>Sr No</th>
//                     <th>Merchant</th>
//                     <th>Coupon Code</th>
//                     <th>Redeemed Date</th>
//                     <th>Status</th>
//                     <th>View</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {coupons.map((coupon, index) => (
//                     <tr key={coupon._id}>
//                       <td className="py-1">{index + 1}</td>
//                       <td className="py-1">{coupon?.couponDetails?.hotelName}</td>
//                       <td className="py-1">{coupon?.couponCode}</td>
//                       <td className="py-1">
//                         {new Date(coupon.redeemedAt).toLocaleDateString('en-GB')}
//                       </td>
//                       <td className="py-1">
//                         {checkStatus(coupon?.redeemedAt) ? 'Active' : 'Expired'}
//                       </td>
//                       <td className="py-1">
//                         <Button
//                           variant="success"
//                           size="sm"
//                           className="mx-0 text-white p-0 px-2"
//                           style={{ backgroundColor: 'green', color: 'white' }}
//                           onClick={() => handleView(coupon)}
//                         >
//                           View
//                         </Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//             </div>
//           </Container>

//           {/* Modal for displaying coupon details */}
//           {selectedCoupon && (
//             <Modal show={showModal} onHide={() => setShowModal(false)}>
//               <Modal.Header closeButton>
//                 <Modal.Title className="w-100 text-center">Coupon Details</Modal.Title>
//               </Modal.Header>
//               <Modal.Body className='text-left'>
//                 <p><strong>Merchant:</strong> {selectedCoupon?.couponDetails?.hotelName}</p>
//                 <p><strong>Coupon Code:</strong> {selectedCoupon?.couponCode}</p>
//                 <p><strong>Redeemed Date:</strong> {new Date(selectedCoupon?.redeemedAt).toLocaleString('en-GB')}</p>
//                 <p><strong>Status:</strong> {checkStatus(selectedCoupon?.redeemedAt) ? 'Active' : 'Expired'}</p>
//               </Modal.Body>
//               {/* <Modal.Footer>
//                 <Button variant="secondary" style={{ backgroundColor: '#EF4C0B', padding: '5px', border: 'none' }} onClick={() => setShowModal(false)}>
//                   Close
//                 </Button>
//               </Modal.Footer> */}
//             </Modal>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Col, Container, Row, Button, Modal, Table } from 'react-bootstrap';
// import Footer from './Footer';
// import { auth } from '../firebase.config'; // Firebase config
// import Sidebar from './Sidebar';
// import UserHeader from './UserHeader';
// import { useNavigate } from 'react-router-dom';

// export default function Offercards() {
//   const [coupons, setCoupons] = useState([]);
//   const [selectedCoupon, setSelectedCoupon] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();
//   const [showSidebar, setShowSidebar] = useState(false); // New state for toggling sidebar

//   const checkStatus = (redeemedAt) => {
//     const redeemedDate = new Date(redeemedAt);
//     const currentDate = new Date();
//     const timeDifference = currentDate - redeemedDate;
//     const hoursDifference = timeDifference / (1000 * 60 * 60);
//     return hoursDifference <= 24;
//   };

//   const isSessionActive = () => {
//     return localStorage.getItem("session") !== null;
//   };

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//         if (user) {
//             setIsLoggedIn(true);
//         } else {
//             setIsLoggedIn(false);
//             if (!isSessionActive()) {
//                 navigate('/signupform'); // Redirect to login if session is not set
//             }
//         }
//     });

//     return () => unsubscribe();
// }, [navigate]);

//   useEffect(() => {
//     const phoneNumber = localStorage.getItem('userPhoneNumber');
//     const fetchCoupons = async () => {
//       try {
//         const response = await axios.post(
//           'https://discountfeast.co.in/couponredeem/getRedeemedDtlByMobNo',
//           { phoneNumber: phoneNumber }
//         );

//         const respData = response?.data;
//         if (respData?.success) {
//           setCoupons(respData?.couponDetails);
//         } else {
//           setCoupons([]);
//         }
//       } catch (error) {
//         setCoupons([]);
//       }
//     };

//     fetchCoupons();
//   }, []);

//   const handleView = (coupon) => {
//     setSelectedCoupon(coupon);
//     setShowModal(true);
//   };

//   return (
//     <>
//       <UserHeader />
//       {/* Sidebar toggle button for mobile view */}
//       <Button
//         className="d-block d-md-none mb-0 bg-dark"
//         onClick={() => setShowSidebar(!showSidebar)}
//         style={{ marginLeft: '0px', color: 'white', border: 'none', borderRadius: '0px', width: '100%', backgroundColor: '#495057' }}
//       >
//         {showSidebar ? 'Hide Menu' : 'Show Menu'}
//       </Button>

//       <div className="d-flex" style={{ borderBottom: '1px solid white' }}>
//         {/* Conditional rendering of sidebar */}
//         {(showSidebar || window.innerWidth >= 768) && <Sidebar />}
        
//         <div className="flex-grow-1 p-3" style={{ overflow: 'auto', maxWidth: '100%' }}>
//           <Row>
//             <Col xs={12}>
//               <h2 className="signuph2 pt-5 text-center">
//                 My Coupons
//               </h2>
//             </Col>
//           </Row>
//           <Container>
//             <div className="table-responsive" style={{ overflow: 'auto', maxWidth: '100%' }}>
//               <Table striped bordered hover className="table-sm" style={{ minWidth: '800px' }}>
//                 <thead className="thead-dark">
//                   <tr>
//                     <th style={{ fontSize: '14px' }}>Sr No</th>
//                     <th style={{ fontSize: '14px' }}>Merchant</th>
//                     <th style={{ fontSize: '14px' }}>Coupon Code</th>
//                     <th style={{ fontSize: '14px' }}>Redeemed Date</th>
//                     <th style={{ fontSize: '14px' }}>Status</th>
//                     <th style={{ fontSize: '14px' }}>View</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {coupons.map((coupon, index) => (
//                     <tr key={coupon._id}>
//                       <td className="py-1" style={{ fontSize: '14px' }}>{index + 1}</td>
//                       <td className="py-1" style={{ fontSize: '14px' }}>{coupon?.couponDetails?.hotelName}</td>
//                       <td className="py-1" style={{ fontSize: '14px' }}>{coupon?.couponCode}</td>
//                       <td className="py-1" style={{ fontSize: '14px' }}>
//                         {new Date(coupon.redeemedAt).toLocaleDateString('en-GB')}
//                       </td>
//                       <td className="py-1" style={{ fontSize: '14px' }}>
//                         {checkStatus(coupon?.redeemedAt) ? 'Active' : 'Expired'}
//                       </td>
//                       <td className="py-1">
//                         <Button
//                           variant="success"
//                           size="sm"
//                           className="mx-0 text-white p-0 px-2"
//                           style={{ backgroundColor: 'green', color: 'white' }}
//                           onClick={() => handleView(coupon)}
//                         >
//                           View
//                         </Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//             </div>
//           </Container>

//           {/* Modal for displaying coupon details */}
//           {selectedCoupon && (
//             <Modal show={showModal} onHide={() => setShowModal(false)}>
//               <Modal.Header closeButton>
//                 <Modal.Title className="w-100 text-center">Coupon Details</Modal.Title>
//               </Modal.Header>
//               <Modal.Body className='text-left'>
//                 <p><strong>Merchant:</strong> {selectedCoupon?.couponDetails?.hotelName}</p>
//                 <p><strong>Coupon Code:</strong> {selectedCoupon?.couponCode}</p>
//                 <p><strong>Redeemed Date:</strong> {new Date(selectedCoupon?.redeemedAt).toLocaleString('en-GB')}</p>
//                 <p><strong>Status:</strong> {checkStatus(selectedCoupon?.redeemedAt) ? 'Active' : 'Expired'}</p>
//               </Modal.Body>
//               {/* <Modal.Footer>
//                 <Button variant="secondary" style={{ backgroundColor: '#EF4C0B', padding: '5px', border: 'none' }} onClick={() => setShowModal(false)}>
//                   Close
//                 </Button>
//               </Modal.Footer> */}
//             </Modal>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Container, Row, Button, Modal, Table } from 'react-bootstrap';
import Footer from './Footer';
import { auth } from '../firebase.config'; // Firebase config
import Sidebar from './Sidebar';
import UserHeader from './UserHeader';
import { useNavigate } from 'react-router-dom';

export default function Offercards() {
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false); // New state for toggling sidebar

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 10; // Number of items per page

  const checkStatus = (redeemedAt) => {
    const redeemedDate = new Date(redeemedAt);
    const currentDate = new Date();
    const timeDifference = currentDate - redeemedDate;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference <= 24;
  };

  const isSessionActive = () => {
    return localStorage.getItem("session") !== null;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        if (!isSessionActive()) {
          navigate('/signupform'); // Redirect to login if session is not set
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const phoneNumber = localStorage.getItem('userPhoneNumber');
    const fetchCoupons = async () => {
      try {
        const response = await axios.post(
          'https://discountfeast.co.in/couponredeem/getRedeemedDtlByMobNo',
          { phoneNumber: phoneNumber }
        );

        const respData = response?.data;
        if (respData?.success) {
          setCoupons(respData?.couponDetails);
        } else {
          setCoupons([]);
        }
      } catch (error) {
        setCoupons([]);
      }
    };

    fetchCoupons();
  }, []);

  const handleView = (coupon) => {
    setSelectedCoupon(coupon);
    setShowModal(true);
  };

  // Pagination logic
  const totalPages = Math.ceil(coupons.length / itemsPerPage);
  const paginatedCoupons = coupons.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <UserHeader />
      {/* Sidebar toggle button for mobile view */}
      <Button
        className="d-block d-md-none mb-0 bg-dark"
        onClick={() => setShowSidebar(!showSidebar)}
        style={{ marginLeft: '0px', color: 'white', border: 'none', borderRadius: '0px', width: '100%', backgroundColor: '#495057' }}
      >
        {showSidebar ? 'Hide Menu' : 'Show Menu'}
      </Button>

      <div className="d-flex" style={{ borderBottom: '1px solid white' }}>
        {/* Conditional rendering of sidebar */}
        {(showSidebar || window.innerWidth >= 768) && <Sidebar />}
        
        <div className="flex-grow-1 p-3" style={{ overflow: 'auto', maxWidth: '100%' }}>
          <Row>
            <Col xs={12}>
              <h2 className="signuph2 pt-5 text-center">
                My Coupons
              </h2>
            </Col>
          </Row>
          <Container>
            <div className="table-responsive" style={{ overflow: 'auto', maxWidth: '100%' }}>
              <Table striped bordered hover className="table-sm" style={{ minWidth: '800px' }}>
                <thead className="thead-dark">
                  <tr>
                    <th style={{ fontSize: '14px' }}>Sr No</th>
                    <th style={{ fontSize: '14px' }}>Merchant</th>
                    <th style={{ fontSize: '14px' }}>Coupon Code</th>
                    <th style={{ fontSize: '14px' }}>Redeemed Date</th>
                    <th style={{ fontSize: '14px' }}>Status</th>
                    <th style={{ fontSize: '14px' }}>View</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedCoupons.length > 0 ? (
                    paginatedCoupons.map((coupon, index) => (
                      <tr key={coupon._id}>
                        <td className="py-1" style={{ fontSize: '14px' }}>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                        <td className="py-1" style={{ fontSize: '14px' }}>{coupon?.couponDetails?.hotelName}</td>
                        <td className="py-1" style={{ fontSize: '14px' }}>{coupon?.couponCode}</td>
                        <td className="py-1" style={{ fontSize: '14px' }}>
                          {new Date(coupon.redeemedAt).toLocaleDateString('en-GB')}
                        </td>
                        <td className="py-1" style={{ fontSize: '14px' }}>
                          {checkStatus(coupon?.redeemedAt) ? 'Active' : 'Expired'}
                        </td>
                        <td className="py-1">
                          <Button
                            variant="success"
                            size="sm"
                            className="mx-0 text-white p-0 px-2"
                            style={{ backgroundColor: 'green', color: 'white' }}
                            onClick={() => handleView(coupon)}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center" style={{ fontSize: '14px' }}>
                        No coupons found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Container>

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-4">
            <Button
              style={{ margin: '0 5px', fontSize: '12px', backgroundColor: '#EF4C0B', border: 'none' }}
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            {[...Array(totalPages)].map((_, pageIndex) => (
              <Button
                key={pageIndex}
                style={{ margin: '0 5px', fontSize: '12px', backgroundColor: '#EF4C0B', border: 'none' }}
                onClick={() => goToPage(pageIndex + 1)}
                active={currentPage === pageIndex + 1}
              >
                {pageIndex + 1}
              </Button>
            ))}
            <Button
              style={{ margin: '0 5px', fontSize: '12px', backgroundColor: '#EF4C0B', border: 'none' }}
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>

          {/* Modal for displaying coupon details */}
          {selectedCoupon && (
            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title className="w-100 text-center">Coupon Details</Modal.Title>
              </Modal.Header>
              <Modal.Body className='text-left'>
                <p><strong>Merchant:</strong> {selectedCoupon?.couponDetails?.hotelName}</p>
                <p><strong>Coupon Code:</strong> {selectedCoupon?.couponCode}</p>
                <p><strong>Redeemed Date:</strong> {new Date(selectedCoupon?.redeemedAt).toLocaleString('en-GB')}</p>
                <p><strong>Status:</strong> {checkStatus(selectedCoupon?.redeemedAt) ? 'Active' : 'Expired'}</p>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
